import PiCard from './PiCard.vue';
import {reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance,onActivated,nextTick} from 'vue';
import PiListUtil,{IPiListDataObj} from './piListUtil';
import language from './piLanguage'
export default defineComponent ({
    name: 'PiList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:IPiListDataObj=reactive<IPiListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                gridTitle:'PI管理主界面',
                queryParam: {},
                cardFrom:'PiList',
                modelComp: PiCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/pi/pageData')
            },
            otherParams:{},
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new PiListUtil(proxy,dataObj);
        })
        onMounted(()=>{
        })
        onActivated(()=>{
            nextTick(()=>{
                //PI首次提交会提示跳转，有可能从那里跳转过来，如果此时PI列表已打开，需要重新查询一下列表内容
                if(proxy.$route.query.from && proxy.$route.query.from=='piCardSubmit'){
                    dataObj.pageListRef.queryHandler();
                }
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'flag'==params.comboId){
                    return [{value:0,label:'未发布'},{value:1,label:'已发布'}];
                }
                if(params && 'status'==params.comboId){
                    return [{value:0,label:'制单'},{value:1,label:'流程中'},{value:2,label:'驳回'},{value:3,label:'结束'}];
                }
            }
        })

        const formatPageInfo=(options:any)=>{
            return options;
        }
        //发布
        const pubHandler=(row:any)=>{
            utils.Tools.configBox({
                message:'确定发布编码为【'+row.F_CODE+'】的PI吗？发布后，客户将能查收此PI信息',
                sureFn:async ()=> {
                    let res=await utils.OrderProviderApi.pubPi({params:{id:row.F_ID}});
                    if(res.result){
                        utils.Tools.success();
                        dataObj.pageListRef.queryHandler();
                    }
                }
            });
        }
        //点击修改的时候，在后台其实已经锁定了pi，当关闭弹出框的时候，需要解锁pi，否则当点击修改打开了pi，不点击提交的时候，pi就永远的锁定了。
        const cancelHandler=async (modelEngineRef:any)=>{
            if(modelEngineRef.id){
                let res=await utils.OrderProviderApi.unlockPi({params:{id:modelEngineRef.id}});
            }
        }
        return{
            ...toRefs(dataObj),utils,comboSelect,formatPageInfo,pubHandler,cancelHandler
        }
    }
});